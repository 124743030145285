import { Pipe, PipeTransform } from '@angular/core';

import { Utils } from '../utils/utils';

@Pipe({
  name: 'ordinalFormat'
})
export class OrdinalFormatterPipe implements PipeTransform {
  transform(value: any): string {
    const formattedValue = !Utils.isNullOrUndefined(value) && value !== 'N/A' ? this.ordinalFormatter(value) : 'N/A';
    return `${formattedValue}`;
  }

  ordinalFormatter(num: any) {
    const digitnum = num % 10;
    const tenthnum = num % 100;

    if (digitnum === 1 && tenthnum !== 11) {
      return num + 'st';
    }

    if (digitnum === 2 && tenthnum !== 12) {
      return num + 'nd';
    }

    if (digitnum === 3 && tenthnum !== 13) {
      return num + 'rd';
    }

    return num + 'th';
  }
}
