import { Component, Input, OnInit } from '@angular/core';
import { EdfxIconList } from './edfx-icon-list.model';

@Component({
  selector: 'edfx-icon-loader',
  templateUrl: './edfx-icon.component.html',
  styleUrls: ['./edfx-icon.component.scss']
})
export class EdfxIconLoaderComponent implements OnInit {
  @Input() iconName: string | null;
  public edfxIconName: string | null = null;
  ngOnInit(): void {
    if (this.iconName && EdfxIconList[this.iconName]) {
      this.edfxIconName = this.iconName;
    } else {
      this.edfxIconName = null;
    }
  }
}
