import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { EdfCardHeaderModule } from '../../edf-card-header/edfx-card-header.module';
import { IconModule } from '../../../shared-module/icons.module';
import { EdfxPipesModule } from '../../../pipes/edfx-pipes.module';
import { EdfxPrivateFinancialStatementsDriverHeatchartComponent } from './edfx-private-financial-statements-drivers-heatchart.component';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoRootModule } from '../../../transloco/transloco-root.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [EdfxPrivateFinancialStatementsDriverHeatchartComponent],
  exports: [EdfxPrivateFinancialStatementsDriverHeatchartComponent],
  imports: [EdfCardHeaderModule, MatIconModule, TranslocoModule, CommonModule, EdfxPipesModule, FlexLayoutModule,
    TranslocoLocaleModule, IconModule, TranslocoRootModule, NoopAnimationsModule, BrowserAnimationsModule, MatTooltipModule],
})
export class EdfxPrivateFinancialStatementsDriverHeatchartModule { }
