import { Injectable } from '@angular/core';
import { Utils } from '../app/utils/utils';
import { HTTP_SESSION_ID } from 'interfaces/constants';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public sessionId!: string | null;

  constructor() {
    this.init();
  }

  private init() {
    let sessionId = sessionStorage.getItem(HTTP_SESSION_ID);
    if (!sessionId) {
      sessionId = Utils.generateUUID();
      sessionStorage.setItem(HTTP_SESSION_ID, sessionId);
    }
    this.sessionId = sessionId;
  }
}
