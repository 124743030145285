export abstract class ChartUtils{
public static displayInactiveMessage(confidenceCode: string, confidenceCodes: string[], isPublic: boolean): boolean {
    if (isPublic || confidenceCode === null) {
      return false;
    }

    const extractedWarning = confidenceCode.toLowerCase().split('+')[0].split('-');

    // Checking last two entries as there are cases where the warning is not at end
    return (
      confidenceCodes.includes(extractedWarning[extractedWarning.length - 1]) ||
      confidenceCodes.includes(extractedWarning[extractedWarning.length - 2])
    );
  }
}
