import { DriverName, DriverValueTypes } from '../interfaces/company-data.interface';
import { Utils } from './utils';

export abstract class DriversUtils {
  static formatRatioText(ratio: number, name: string, valueType: string, currency: string = ''): string {
    if (DriverValueTypes.RATIO === valueType) {
      if (ratio > 1) {
        return `${Utils.formatNumberString(ratio)}x`;
      }
      return `${(ratio * 100).toFixed(2)}%`;
    } else if (DriverValueTypes.VALUE === valueType) {
      if (name.toLowerCase().includes(DriverName.YEARS_IN_BUSINESS)) {
        return `${ratio.toFixed(0)} years`;
      }
      return `${currency} ${this.formatValueWithThousandsSuffix(ratio)}`;
    } else {
      return ratio.toFixed(2);
    }
  }

  static formatValueWithThousandsSuffix(value: number): string {
    // This function assumes the value coming in is already formatted in thousands
    if (value > 1000000000) {
      return `${(value / 1000000000).toFixed(2)}T`;
    }
    if (value > 1000000) {
      return `${(value / 1000000).toFixed(2)}B`;
    }
    if (value > 1000) {
      return `${(value / 1000).toFixed(2)}M`;
    }
    return `${value.toFixed(2)}K`;
  }
}
