import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EdfxPrivateFinancialStatementsDriverHeatchartComponent } from './components/views/edfx-private-drivers-heatchart/edfx-private-financial-statements-drivers-heatchart.component';
import { EdfxPrivateFinancialStatementsDriverHeatchartModule } from './components/views/edfx-private-drivers-heatchart/edfx-private-financial-statements-drivers-heatchart.module';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, EdfxPrivateFinancialStatementsDriverHeatchartModule],
  providers: [],
  bootstrap: []
})
export class AppModule {
  constructor(injector: Injector) {
    const financialStatementsDriverElement = createCustomElement(EdfxPrivateFinancialStatementsDriverHeatchartComponent, {
      injector
    });
    customElements.define('edfx-financial-statements-driver', financialStatementsDriverElement);
  }
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngDoBootstrap() {}
}
