import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'edfx-card-header',
  templateUrl: './edf-card-header.component.html',
  styleUrls: ['./edf-card-header.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EdfCardHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() titleClass = '';
  @Input() hasData = true;
  @Input() showFullscreen = true;
  @Input() showDownloadFullscreen = true;
  @Input() showDownload = true;
  @Input() titleIcon: string | null = null;
  @Output() public fullScreen: EventEmitter<void> = new EventEmitter<void>();
  @Output() downloadAsPng: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onFullScreenClick() {
    this.fullScreen.emit();
  }

  onDownloadAsPngClick() {
    this.downloadAsPng.emit();
  }

  getShowDownloadFullscreen() {
    return this.hasData && this.showDownloadFullscreen;
  }

  getTitleClass() {
    return !!this.titleClass ? this.titleClass : 'edfx-card-header-title';
  }
}
