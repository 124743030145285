export interface IFinancialDriversCustomData {
    entityId: string;
    heatMapSize?: HeatMapSize;
    asOfDate?: string;
  }

  export enum HeatMapSize {
    small = 0,
    medium = 1,
    mediumLarge = 2,
    large = 3
  }
