import moment from 'moment';
import { Utils } from './utils';

export class DateUtils {
  public static getLatestDate(latestKnownPdDate: string): moment.Moment {
    return !Utils.isNullOrUndefined(latestKnownPdDate) ? moment(latestKnownPdDate) : moment(Date.now());
  }

  /**
   * Converts a server time zone to local time zone
   *
   * @param dateTime The colored-risk-renderer in string format with zone.
   */
  public static convertServerTimeToLocalTime(dateTime: string) {
    dateTime = dateTime ? dateTime.split('+')[0] : dateTime;
    const currentTimeZoneOffset = new Date().getTimezoneOffset();
    const serverTimeZoneOffset = new Date(moment(dateTime).utc().format('YYYY-MM-DD HH:mm:ss')).getTimezoneOffset();
    return moment(dateTime)
      .utcOffset(-(currentTimeZoneOffset + serverTimeZoneOffset))
      .format('YYYY-MM-DD HH:mm:ss');
  }
}
