<div class="edfx-card-header-container d-flex align-items-center flex-row flex-1">
  <span class="flex align-items-center" [ngClass]="getTitleClass()"
    ><edfx-icon-loader class="margin-r-2" *ngIf="titleIcon" [iconName]="titleIcon"></edfx-icon-loader> {{title}}</span
  >
  <div class="d-flex flex-1 align-items-center" *ngIf="getShowDownloadFullscreen()">
    <div class="flex-1 align-items-center">
      <ng-content></ng-content>
    </div>
    <span
      *ngIf="showDownload"
      class="material-icons icon-button margin-lr-5"
      title="{{'GLOBAL.KEYWORD.DOWNLOAD' | transloco}}"
      (click)="onDownloadAsPngClick()"
    >
      file_download
    </span>
    <span
      *ngIf="showFullscreen"
      class="material-icons icon-button"
      title="{{'GLOBAL.KEYWORD.FULLSCREEN' | transloco}}"
      (click)="onFullScreenClick()"
    >
      open_in_full
    </span>
  </div>
</div>
