<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined" rel="stylesheet" />
<div
  *ngIf="!loading && isPrivateModel && !displayInactiveMessage && !requestInProgress && !tokenInvalid && hasEntitlement && !isLoading()"
  class="d-flex flex-row font"
  style="margin-top: 20px"
>
  <div class="full-width card card-container mb-4 ms-4 me-4" style="overflow-x: hidden">
    <div class="card-body">
      <div class="d-flex flex-column mb-2">
        <edfx-card-header [titleIcon]="titleIcon" [title]="driversTitle" [showFullscreen]="false" [showDownload]="false">
        </edfx-card-header>
        <div *ngIf="showInfo">
          <span class="statement-drivers-model"> {{ localizationData.COMPANY.DRIVERS.FINANCIAL_STATEMENT_DRIVERS.MODEL }} </span>:
          {{getCompanyModelName()}}
        </div>
        <div class="statement-drivers-info" *ngIf="showInfo">{{ localizationData.COMPANY.DRIVERS.FINANCIAL_STATEMENT_DRIVERS.INFO }}</div>
        <div
          *ngIf="(!loading && (!hasMmsModel || !hasRatiosAndPercentiles)) && !displayInactiveMessage && !hasData()"
          class="d-flex flex-row align-items-center justify-content-center full-height full-width"
        >
          <span class="override-message"> {{localizationData.GLOBAL.DATA.NO_DATA_TO_DISPLAY}} </span>
        </div>
        <div
          *ngIf="!loading && displayInactiveMessage"
          class="d-flex flex-row align-items-center justify-content-center full-height full-width"
        >
          <span class="override-message"> {{inactiveMessage}} </span>
        </div>
        <div class="d-flex flex-row align-items-center full-width" *ngIf="!loading && !displayInactiveMessage && !invalidEntity">
          <div *ngIf="hasCategoryColumnList()" class="d-flex flex-column category-header-width">
            <div class="d-flex align-items-center row-even ps-3 headers row-height">
              <span *ngIf="!showInfo">{{ localizationData.COMPANY.DRIVERS.HEATCHART.REPORT_DRIVER_CATEGORY_WEIGHT }}</span>
              <span *ngIf="showInfo">{{ localizationData.COMPANY.DRIVERS.HEATCHART.DRIVER_CATEGORY_WEIGHT }}</span>
              <div class="tooltip-box">
                <span *ngIf="showInfo" class="material-icons-outlined icon"> info </span>
                <div class="tooltip-text">{{ localizationData.COMPANY.DRIVERS.HEATCHART.DRIVER_CATEGORY_WEIGHT_TOOLTIP }}</div>
              </div>
            </div>
            <div *ngFor="let category of categoryColumnList">
              <div class="d-flex align-items-center ps-3 cell-border" [ngStyle]="{ 'height': getCategoryHeight(category.driverCount) }">
                <span class="row-font">{{category.name}}</span>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column full-width">
            <div class="d-flex flex-row align-items-center full-width row-even row-height headers">
              <div
                class="d-flex ms-5"
                [ngClass]="{'driver-cell-width': !isHeatmapLarge(), 'driver-cell-width-medium-large': isHeatmapMediumLarge(), 'driver-cell-width-large': isHeatmapLarge()}"
              >
                {{ localizationData.COMPANY.DRIVERS.HEATCHART.DRIVERS }}
              </div>
              <div
                class="d-flex"
                [ngClass]="{'heatchart-cell-width': !isHeatmapLarge(), 'heatchart-medium-large-cell-width': isHeatmapMediumLarge(), 'heatchart-large-cell-width': isHeatmapLarge()}"
              >
                {{ localizationData.COMPANY.DRIVERS.HEATCHART.DRIVER_VALUE_PERCENTILE }}
              </div>
            </div>
            <div
              *ngFor="let collection of heatchart.collection; let index = index"
              class="d-flex flex-row align-items-center full-width cell-border row-height"
              [ngClass]="{ 'row-even': index % 2 !== 0 }"
            >
              <div
                class="d-flex ms-5"
                [ngClass]="{'driver-cell-width': !isHeatmapLarge(), 'driver-cell-width-medium-large': isHeatmapMediumLarge(), 'driver-cell-width-large': isHeatmapLarge()}"
              >
                <span class="row-font">{{getDriverName(index)}}</span>
              </div>
              <div
                class="d-flex"
                [ngClass]="{'heatchart-cell-width': !isHeatmapLarge(), 'heatchart-medium-large-cell-width': isHeatmapMediumLarge(), 'heatchart-large-cell-width': isHeatmapLarge()}"
              >
                <div [ngClass]="heatmapContainerBarClass">
                  <div class="d-flex heatchart" [ngStyle]="{ 'background': getColor(index) }"></div>
                </div>
                <div *ngIf="hasDriverData(index)" [ngClass]="heatmapContainerClass">
                  <div class="content" [ngStyle]="{ 'left': getPercentile(index) }">
                    <div class="text-top">{{getRatioForSliderText(index)}} | {{ getPercentileForSliderText(index) | ordinalFormat}}</div>
                    <div class="slider-position">
                      <mat-icon class="icon-size">arrow_drop_down</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-around center">
  <div class="spinner" *ngIf="loading && hasEntitlement"></div>
</div>
<p class="token-invalid-message" *ngIf="tokenInvalid && !requestInProgress">{{ localizationData.TOKEN.INVALID_MESSAGE }}</p>
<p class="financial-driver-unavailable" *ngIf="!tokenInvalid && financialStatementDriverUnavailable">
  {{ localizationData.COMPANY.DRIVERS.FINANCIAL_STATEMENT_DRIVERS.UNAVAILABLE }}
</p>
<ng-container *ngIf="!hasEntitlement && !requestInProgress">
  <p class="token-invalid-message">User does not have EDF-X access. Please contact your Moody's representative.</p>
</ng-container>
<ng-container *ngIf="!loading && invalidEntity">
  <p class="token-invalid-message">Entity id {{entityId}} does not exists.</p>
</ng-container>
