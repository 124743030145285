import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DEFAULT_TEXT_NA, Entitlements, FREQUENCY_MONTHLY, INSOLVENT_BANKRUPT, MOMENT_FORMATS } from 'interfaces/constants';
import { IEntitySearchResponse } from 'projects/company-profile/src/app/types';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  ICompanyModel,
  ICompanyProfile,
  IHistoricalPdDriver,
  IPDHistoricalInputPayload,
  IPdDriversChartData,
  ModelId
} from '../../../interfaces/company-data.interface';
import { IEntitlement } from '../../../interfaces/entitlement.interface';
import { HeatMapSize, IFinancialDriversCustomData } from '../../../interfaces/financial-driver-custom-data.interface';
import { ICategoryColumn, IHeatchart, IHeatchartDriverDetail } from '../../../interfaces/heatchart.interface';
import { IMmsModel, IPdModelCategoryDriver } from '../../../interfaces/mms-model.interface';
import { ApiService } from '../../../services/api.service';
import { ChartUtils } from '../../../utils/charts.util';
import { DateUtils } from '../../../utils/date.utils';
import { DriversUtils } from '../../../utils/drivers.utils';
import { Utils } from '../../../utils/utils';
import * as translocoData from '../../../../assets/i18n/en.financial-drivers.json';

export enum HeatmapSize {
  small = 0,
  medium = 1,
  mediumLarge = 2,
  large = 3
}

@Component({
  selector: 'edfx-private-financial-statements-drivers-heatchart',
  templateUrl: './edfx-private-financial-statements-drivers-heatchart.component.html',
  styleUrls: ['./edfx-private-financial-statements-drivers-heatchart.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class EdfxPrivateFinancialStatementsDriverHeatchartComponent implements OnInit, OnChanges, OnDestroy {
  financialDriversCustomData: IFinancialDriversCustomData;
  @Input() isFinancialModel = true;
  @Input() showInfo = true;
  @Input() public titleIcon: string | null = null;
  @Input() public inactiveMessage = '';
  @Input() inputData!: string | IFinancialDriversCustomData;
  @Input() token!: string;

  public isHeatmapLarge(): boolean {
    return this.heatmapContainerClass.includes('large') ? true : false;
  }

  public isHeatmapMediumLarge(): boolean {
    return this.heatmapContainerClass.includes('medium-large') ? true : false;
  }

  public heatmapContainerClass = 'medium-container';
  public heatmapContainerBarClass = 'medium-container-bar';

  public model?: IMmsModel;
  public heatchartRatiosAndPercentiles?: IHeatchartDriverDetail[] = [];
  public heatchart: IHeatchart | undefined;
  public colorCollection: any;
  public categoryColumnList: ICategoryColumn[];

  public driverNameList: string[];
  companyName: string | undefined;
  public loading: boolean | undefined;
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public pdDriversChartData?: IPdDriversChartData;
  public historicalDriverInputs: IPDHistoricalInputPayload | undefined;
  public companyModel?: ICompanyModel;
  private primaryModelId = '';
  public title = '';
  public hasMmsModel: boolean | undefined;
  public hasRatiosAndPercentiles: boolean | undefined;
  public displayInactiveMessage = false;
  public isPrivateModel = false;
  public companyInfoLoading = true;
  public isBenchmarkModel = false;
  public isPublicModel = false;
  public isPaymentModel = false;
  public hasNoModel = false;
  hasEntitlement = false;
  tokenInvalid = false;
  requestInProgress = true;
  financialStatementDriverUnavailable = false;
  entityId: string;
  analysisDate: string;
  private static readonly baseRowHeight = 52;
  getMonthlyHistoricalPdDrivers$?: Observable<IPdDriversChartData>;
  mmsModel$?: Observable<IMmsModel>;
  todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  invalidEntity = false;
  localizationData: any;
  driversTitle: string;

  constructor(private translocoService: TranslocoService, private apiService: ApiService) {
    // Only for dev testing, comment out for building the web element!
    /*
    this.financialDriversCustomData = {
      entityId: 'IE160273'
    };
    this.token = // eslint-disable-next-line
      '';
    this.inputData = JSON.stringify(this.financialDriversCustomData);
    */
    this.categoryColumnList = [];
    this.driverNameList = [];
    this.initializeHeatchartConfig();
    this.initializeColorCollection();
  }

  ngOnInit(): void {
    this.localizationData = JSON.parse(JSON.stringify(translocoData));
    this.title = this.localizationData.COMPANY.DRIVERS.HISTORICAL_DRIVER_CONTRIBUTION_GRID.TITLE;
    this.driversTitle = this.localizationData.COMPANY.DRIVERS.FINANCIAL_STATEMENT_DRIVERS.TITLE;
    this.inactiveMessage = this.localizationData.CHART.OVERWRITE_MESSAGE;
    if (!Utils.isNullOrUndefined(this.inputData) && !Utils.isNullOrUndefined(this.token)) {
      this.getFinancialDriversCustomDataFromInput();
    }
  }

  getFinancialDriversCustomDataFromInput() {
    if (typeof this.inputData === 'string') {
      this.financialDriversCustomData = JSON.parse(this.inputData) as IFinancialDriversCustomData;
    } else if (typeof this.inputData === 'object') {
      this.financialDriversCustomData = this.inputData;
    }
    this.initializeComponent(this.financialDriversCustomData);
  }

  initializeComponent(financialDriversCustomData: IFinancialDriversCustomData): void {
    this.loading = true;
    const entitlement_list = [
      Entitlements.MIR_MEDIAN_CREDIT_SPREAD,
      Entitlements.ORBIS_ALL_COMPANIES,
      Entitlements.PRIVATE_ENTITLEMENT,
      Entitlements.PUBLIC_ENTITLEMENT,
      Entitlements.PUBLIC_PLUS_ENTITLEMENT
    ];
    const entityId = financialDriversCustomData?.entityId;

    if (!Utils.isNullOrUndefined(this.token)) {
      this.apiService.setToken(this.token);
    }

    if (!entityId) {
      return;
    }
    this.apiService
      .getEntitlements(entitlement_list)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loading = false;
          }
          this.requestInProgress = false;
          this.hasEntitlement = true;
          this.loading = false;
          return of();
        })
      )
      .subscribe(entitlements => this.onEntitlementsReceived(entitlements, financialDriversCustomData));
  }

  onEntitlementsReceived(entitlements: IEntitlement[], financialDriversCustomData: IFinancialDriversCustomData) {
    this.hasEntitlement = entitlements?.some(
      entitlement => entitlement.name === Entitlements.PRIVATE_ENTITLEMENT || entitlement.name === Entitlements.PUBLIC_ENTITLEMENT
    );
    if (!this.hasEntitlement) {
      this.requestInProgress = false;
      this.loading = false;
      return;
    }
    this.entityId = financialDriversCustomData?.entityId;
    this.analysisDate = !!financialDriversCustomData?.asOfDate ? financialDriversCustomData?.asOfDate : this.todaysDate;
    this.setHeatMapSize(financialDriversCustomData?.heatMapSize);
    this.requestInProgress = true;
    this.tokenInvalid = false;

    this.getEntityId();
  }

  getEntityId(): void {
    this.invalidEntity = false;
    const currentEntityId = this.entityId;
    this.apiService
      .getEntityId(currentEntityId, false)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loading = false;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntity = true;
          this.loading = false;
          return;
        }
        if (entityIdResult.entities.length === 0) {
          this.getCustomEntityId(currentEntityId);
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  getCustomEntityId(currentEntityId: string) {
    this.apiService
      .getEntityId(currentEntityId, true)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loading = false;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.invalidEntity = true;
          this.loading = false;
          return;
        }

        if (entityIdResult.entities.length === 0) {
          this.invalidEntity = true;
          this.loading = false;
          return;
        }
        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  onEntityIdReceived() {
    combineLatest([this.apiService.getModel(this.entityId, this.analysisDate), this.apiService.getCompanyProfile(this.entityId)])
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
            this.loading = false;
          }
          this.requestInProgress = false;
          return of();
        })
      )
      .subscribe(([model, profile]) => {
        this.onEntityInfoRecieved(model, profile);
      });
  }

  private setHeatMapSize(value: HeatMapSize) {
    switch (value) {
      case HeatMapSize.small:
        this.heatmapContainerClass = 'small-container';
        this.heatmapContainerBarClass = 'small-container-bar';
        break;
      case HeatMapSize.large:
        this.heatmapContainerClass = 'large-container';
        this.heatmapContainerBarClass = 'large-container-bar';
        break;
      case HeatMapSize.mediumLarge:
        this.heatmapContainerClass = 'medium-large-container';
        this.heatmapContainerBarClass = 'medium-large-container-bar';
        break;
      default:
        this.heatmapContainerClass = 'medium-container';
        this.heatmapContainerBarClass = 'medium-container-bar';
        break;
    }
  }

  private onEntityInfoRecieved(model: ICompanyModel, profile: ICompanyProfile): void {
    if (!model || !profile) {
      this.companyInfoLoading = true;
      return;
    }
    this.requestInProgress = false;
    this.companyInfoLoading = false;
    this.companyModel = model;
    this.companyName = profile.name || '';

    const modelId = this.companyModel.modelId;
    this.primaryModelId = modelId?.split('+')[0];
    if (!this.primaryModelId) {
      this.hasNoModel = true;
      this.loading = false;
      return;
    }

    this.isBenchmarkModel = this.primaryModelId.toLowerCase().includes(ModelId.BENCHMARK);
    this.isPaymentModel = this.primaryModelId.toLowerCase().includes(ModelId.PAYMENT);
    this.isFinancialModel = !this.isBenchmarkModel && !this.isPaymentModel;
    const numberOfYears = this.isFinancialModel ? 5 : 4;
    const latestKnownDate = DateUtils.getLatestDate(this.companyModel.latestKnownPdDate ?? '');
    const inputEndDate = latestKnownDate.format(MOMENT_FORMATS.payload);
    const inputStartDate = latestKnownDate.subtract(numberOfYears, 'years').format(MOMENT_FORMATS.payload);

    this.historicalDriverInputs = {
      entityId: this.companyModel.id ?? '',
      endDate: inputEndDate,
      startDate: inputStartDate,
      frequency: FREQUENCY_MONTHLY,
      fso: true
    };

    this.isPublicModel = this.primaryModelId.toLowerCase().includes(ModelId.EDF9);
    this.isPrivateModel = !this.isBenchmarkModel && !this.isPublicModel;
    this.financialStatementDriverUnavailable = this.isBenchmarkModel || this.isPublicModel;

    if (model?.confidenceIndicator) {
      this.displayInactiveMessage = ChartUtils.displayInactiveMessage(model.confidenceIndicator, INSOLVENT_BANKRUPT, this.isPublicModel);
    }

    if (!this.financialStatementDriverUnavailable) {
      this.subscribeToMonthlyPDHistoricalDataAndmmsData();
    } else {
      this.loading = false;
    }
  }

  public isLoading() {
    if (this.companyInfoLoading) {
      return true;
    }

    if (this.isBenchmarkModel) {
      return false;
    }

    if (this.isPublicModel) {
      return false;
    }

    if (this.isPrivateModel) {
      return false;
    }

    if (this.hasNoModel) {
      return false;
    }

    return true;
  }

  private subscribeToMonthlyPDHistoricalDataAndmmsData() {
    this.mmsModel$ = this.apiService.getMmsModel(this.primaryModelId, true, true, true);
    combineLatest([this.apiService.getMonthlyHistoricalPdDrivers(this.historicalDriverInputs), this.mmsModel$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([historicalPdDrivers, mmsModel]) => {
        if (!historicalPdDrivers || !mmsModel) {
          return;
        }
        // Check if historicalPdDrivers is of type IPdDriversChartData
        if (
          'startDate' in historicalPdDrivers &&
          'endDate' in historicalPdDrivers &&
          'fso' in historicalPdDrivers &&
          'frequency' in historicalPdDrivers &&
          'entityId' in historicalPdDrivers
        ) {
          this.processHistoricalPdDrivers(historicalPdDrivers);
        }
        this.processMmsModel(mmsModel);
        if (this.model && this.pdDriversChartData && this.hasRatiosAndPercentiles) {
          this.constructRatiosAndPercentilesForHeatchart();
        } else {
          this.loading = false;
        }
      });
  }

  private processHistoricalPdDrivers(historicalPdDrivers: IPdDriversChartData): void {
    if (!historicalPdDrivers || !this.companyModel) {
      return;
    }

    this.pdDriversChartData = historicalPdDrivers;

    this.pdDriversChartData.lastKnownPd =
      this.companyModel?.latestKnownPdValue && !isNaN(this.companyModel.latestKnownPdValue)
        ? this.companyModel?.latestKnownPdValue * 100
        : this.companyModel?.latestKnownPdValue;
    this.pdDriversChartData.lastKnownPdDate = this.companyModel.latestKnownPdDate;

    this.pdDriversChartData.quantitativePd =
      this.companyModel?.latestKnownQuantitativePd && !isNaN(this.companyModel.latestKnownQuantitativePd)
        ? this.companyModel.latestKnownQuantitativePd * 100
        : this.companyModel?.latestKnownQuantitativePd;

    this.hasRatiosAndPercentiles = this.pdDriversChartData?.ratios.length > 0 && this.pdDriversChartData.percentiles?.length > 0;
  }

  private processMmsModel(mmsModel: IMmsModel): void {
    this.model = mmsModel;
    this.hasMmsModel = true;
  }

  private constructRatiosAndPercentilesForHeatchart() {
    const driversChartDataLatestRatio: IHistoricalPdDriver = Utils.findLatestObjByDate(this.pdDriversChartData.ratios);
    const driversChartDataLatestPercentile: IHistoricalPdDriver = Utils.findLatestObjByDate(this.pdDriversChartData.percentiles);
    const driversMetadata: IPdModelCategoryDriver[] = this.collectDriversMetadata();
    this.heatchartRatiosAndPercentiles = [];

    driversMetadata.forEach(driverMetadata => {
      const driverName = this.getDriverNameFromDriverMetadata(driverMetadata);
      const ratio = driversChartDataLatestRatio.drivers.find(driver => driver.name.toLowerCase() === driverName.toLowerCase());
      const percentile = driversChartDataLatestPercentile.drivers.find(driver => driver.name.toLowerCase() === driverName.toLowerCase());
      this.heatchartRatiosAndPercentiles?.push({
        name: driverName,
        ratio: ratio?.value,
        percentile: percentile?.value,
        valueType: driverMetadata.valueType,
        currency: driverMetadata.currency
      });
    });
    this.parseData();
  }

  private getDriverNameFromDriverMetadata(driverMetadata: IPdModelCategoryDriver): string {
    const parseIndex = driverMetadata.label.indexOf(':');
    const driverName = this.model.metadata.modelId.toLowerCase().includes(ModelId.PAYMENT)
      ? driverMetadata.cddName
      : parseIndex > 0
      ? driverMetadata.label.substring(parseIndex + 2)
      : driverMetadata.label;

    return driverName;
  }

  private collectDriversMetadata() {
    const driversMetada: IPdModelCategoryDriver[] = [];
    this.model.metadata.pdModelDrivers.categories.map(category => {
      category.drivers.map(driver => driversMetada.push(driver));
    });
    return driversMetada;
  }

  getCompanyModelName() {
    return this.companyModel?.modelName || DEFAULT_TEXT_NA;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['token'] && !Utils.isNullOrUndefined(changes['token'].currentValue)) {
      this.token = changes['token'].currentValue;
    }

    if (changes['inputData'] && !Utils.isNullOrUndefined(changes['inputData'].currentValue)) {
      this.inputData = changes['inputData'].currentValue;
      this.getFinancialDriversCustomDataFromInput();
      this.parseData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public hasData() {
    return !Utils.isNullOrUndefined(this.model) && !Utils.isNullOrUndefined(this.heatchartRatiosAndPercentiles);
  }

  public getColor(index: number): string {
    const percentage = this.getPercentage(this.heatchart.collection[index]).map(value => {
      const percentageString = value.toFixed(2);
      return Math.round(Number(percentageString) * 100).toString() + '%';
    });
    const colorString = this.cssString(this.heatchart.collection[index], percentage);

    this.heatchart?.gradientCollection.push(colorString);

    return colorString;
  }

  public getCategoryHeight(multiplier: number): string {
    const height = EdfxPrivateFinancialStatementsDriverHeatchartComponent.baseRowHeight * multiplier;
    return `${height}px`;
  }

  public getDriverName(index: number): string {
    return this.driverNameList[index];
  }

  public hasDriverData(index: number): boolean {
    if (Utils.isNullOrUndefined(this.heatchartRatiosAndPercentiles) || index >= this.heatchartRatiosAndPercentiles.length) {
      return false;
    }

    const heatchartRatiosAndPercentile = this.heatchartRatiosAndPercentiles[index];
    if (!heatchartRatiosAndPercentile) {
      return false;
    }

    return (
      !Utils.isNullOrUndefined(heatchartRatiosAndPercentile.ratio) && !Utils.isNullOrUndefined(heatchartRatiosAndPercentile.percentile)
    );
  }

  public getPercentile(index: number): string {
    return `${this.heatchartRatiosAndPercentiles[index].percentile.toFixed(2)}%`;
  }

  public getRatioForSliderText(index: number): string {
    const ratio = this.heatchartRatiosAndPercentiles[index].ratio;
    const name = this.heatchartRatiosAndPercentiles[index].name;
    const valueType = this.heatchartRatiosAndPercentiles[index].valueType;
    const currency = this.heatchartRatiosAndPercentiles[index].currency;
    return DriversUtils.formatRatioText(ratio, name, valueType, currency);
  }

  public getPercentileForSliderText(index: number): string {
    return this.heatchartRatiosAndPercentiles[index].percentile.toFixed(0);
  }

  public hasCategoryColumnList() {
    return this.categoryColumnList.length > 0;
  }

  private createColors(heatchartConfig: any): Array<string> {
    const colorsArray: any = this.translateToHexCollection(heatchartConfig.colorCount, heatchartConfig.red, heatchartConfig.yellow);
    const colorsArray2: any = this.translateToHexCollection(heatchartConfig.colorCount, heatchartConfig.yellow, heatchartConfig.green);

    const all = colorsArray2.concat(colorsArray);

    return all;
  }

  private translateToHexCollection(colorCount: number, color1: string, color2: string) {
    const collection = [];

    const min = parseInt(color1.substring(0, 2), 16);
    const mid = parseInt(color1.substring(2, 4), 16);
    const max = parseInt(color1.substring(4, 6), 16);

    const rightMin = parseInt(color2.substring(0, 2), 16);
    const rightMid = parseInt(color2.substring(2, 4), 16);
    const rightMax = parseInt(color2.substring(4, 6), 16);

    for (let i = 0; i <= colorCount; i++) {
      const colorCombo = {
        r: min * (i / colorCount) + rightMin * (1 - i / colorCount),
        g: mid * (i / colorCount) + rightMid * (1 - i / colorCount),
        b: max * (i / colorCount) + rightMax * (1 - i / colorCount)
      };

      const r = Math.ceil(colorCombo.r);
      const g = Math.ceil(colorCombo.g);
      const b = Math.ceil(colorCombo.b);

      collection.push(`#${this.getHex(r)}${this.getHex(g)}${this.getHex(b)}`);
    }

    return collection;
  }

  private parseData(): any {
    if (!this.model || !this.heatchartRatiosAndPercentiles) {
      return;
    }
    this.categoryColumnList = [];
    this.driverNameList = [];
    this.heatchart.collection = [];
    for (const category of this.model.metadata.pdModelDrivers.categories) {
      for (const driver of category.drivers) {
        const rowWeight: string[] = [];
        driver.transformWeights.forEach(weight => {
          const colorString = this.calc(weight);
          rowWeight.push(colorString);
        });
        this.heatchart.collection.push(rowWeight);
        this.driverNameList.push(this.parseDriverLabel(driver.label));
      }
      if (!!category.name) {
        const categoryColumn = {
          name: this.getCategoryText(category.name, category.weight),
          driverCount: category.drivers.length
        };
        this.categoryColumnList.push(categoryColumn);
      }
    }
    this.loading = false;
  }

  private parseDriverLabel(label: string): string {
    if (!!this.model && this.model.metadata.modelId.toLowerCase().includes(ModelId.PAYMENT)) {
      return label;
    }
    return label.substring(label.indexOf(':') + 2);
  }

  private getCategoryText(name: string, weight: number): string {
    return `${name} | ${(weight * 100).toFixed(2)}%`;
  }

  private calc(value: number): any {
    if (!!this.heatchart) {
      return this.percentileColorCssString(this.heatchart.config.gradient, value);
    }
  }

  private getHex(code: any): string {
    if (code >= 100) {
      code--;
    }

    code = code.toString(16);
    const hexString = code.length === 1 ? '0' + code : code;

    return hexString;
  }

  private initializeHeatchartConfig() {
    this.heatchart = {
      config: {
        colorCount: 50,
        red: 'd65040',
        yellow: 'f5ec69',
        green: '83b562',
        gradient: []
      },
      collection: [],
      gradientCollection: []
    };

    this.heatchart.config.gradient = this.createColors(this.heatchart.config);
  }

  private initializeColorCollection() {
    if (!!this.heatchart) {
      this.colorCollection = {
        green: {
          r: parseInt(this.heatchart.config.green.substring(0, 2), 16),
          g: parseInt(this.heatchart.config.green.substring(2, 4), 16),
          b: parseInt(this.heatchart.config.green.substring(4, 6), 16)
        },
        yellow: {
          r: parseInt(this.heatchart.config.yellow.substring(0, 2), 16),
          g: parseInt(this.heatchart.config.yellow.substring(2, 4), 16),
          b: parseInt(this.heatchart.config.yellow.substring(4, 6), 16)
        },
        red: {
          r: parseInt(this.heatchart.config.red.substring(0, 2), 16),
          g: parseInt(this.heatchart.config.red.substring(2, 4), 16),
          b: parseInt(this.heatchart.config.red.substring(4, 6), 16)
        }
      };
    }
  }

  private getPercentage(array: any[]): number[] {
    return array.map((value, index, arr) => index / arr.length);
  }

  private cssString(color: any, percentage: string[]): any {
    let rule = 'linear-gradient(to right,';
    color.forEach((le: string, index: number) => {
      if (index < color.length - 1) {
        rule += ` ${le} ${percentage[index]},`;
      } else {
        rule += ` ${le} ${percentage[index]})`;
      }
    });

    return rule;
  }

  private percentileColorCssString(color: string[], data: number): any {
    const len = color.length - 1;
    const index = Math.round(len * data);

    return color[index];
  }
}
