import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EdfxIconList } from 'projects/financial-statements-drivers-heatchart/src/app/components/edfx-icon-loader/edfx-icon-list.model';
import { EdfxIconLoaderComponent } from 'projects/financial-statements-drivers-heatchart/src/app/components/edfx-icon-loader/edfx-icon.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [EdfxIconLoaderComponent],
  imports: [MaterialModule, CommonModule],
  exports: [EdfxIconLoaderComponent]
})
export class IconModule {
  private path = '../assets/edfx-mat-icons';

  constructor(private domSanitizer: DomSanitizer, public matIconRegistry: MatIconRegistry) {
    for (const key in EdfxIconList) {
      if (key) {
        this.matIconRegistry.addSvgIcon(key, this.setPath(`${this.path}/${key}.svg`));
      }
    }
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
